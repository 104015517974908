<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
// import { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'

export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getPumpInformation()
      // Object.freeze(tmp)
      this.complain_detail = tmp
    }
  },
  data () {
    return {
      complain_detail: '',
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('org_pro.organization'), val: this.complain_detail.org_name_bn, key1: this.$t('irriPumpInformation.project'), val1: this.complain_detail.project_name_bn },
          { key: this.$t('complain.division'), val: this.complain_detail.division_name_bn, key1: this.$t('complain.district'), val1: this.complain_detail.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.complain_detail.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.complain_detail.union_name_bn },
          { key: this.$t('irriPumpInformation.pump_id'), val: this.$n(this.complain_detail.pump_id), key1: this.$t('irriPumpInformation.mouza_no'), val1: this.complain_detail.mouza_id ? this.getMouzaName(this.complain_detail.mouza_id) : '' },
          { key: this.$t('irriPumpInformation.jl_no'), val: this.$n(this.complain_detail.jl_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.plot_no'), val1: this.$n(this.complain_detail.plot_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.latitude'), val: this.$n(this.complain_detail.latitude, { useGrouping: false }), key1: this.$t('irriPumpInformation.longitude'), val1: this.$n(this.complain_detail.longitude, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.water_group_id'), val: this.$n(this.complain_detail.water_group_id) },
          { key: this.$t('externalUserIrrigation.total_farmer'), val: this.$n(this.complain_detail.total_farmer) }
        ]
      } else {
          return [
            { key: this.$t('org_pro.organization'), val: this.complain_detail.org_name, key1: this.$t('irriPumpInformation.project'), val1: this.complain_detail.project_name },
            { key: this.$t('complain.division'), val: this.complain_detail.division_name, key1: this.$t('complain.district'), val1: this.complain_detail.district_name },
            { key: this.$t('complain.upazila'), val: this.complain_detail.upazilla_name, key1: this.$t('complain.union'), val1: this.complain_detail.union_name },
            // { key: this.$t('irriPumpInformation.pump_id'), val: this.complain_detail.pump_id, key1: this.$t('irriPumpInformation.mouza_no'), val1: this.complain_detail.mouza_no },
            { key: this.$t('irriPumpInformation.pump_id'), val: this.complain_detail.pump_id, key1: this.$t('irriPumpInformation.mouza_no'), val1: this.complain_detail.mouza_id ? this.getMouzaName(this.complain_detail.mouza_id) : '' },
            { key: this.$t('irriPumpInformation.jl_no'), val: this.complain_detail.jl_no, key1: this.$t('irriPumpInformation.plot_no'), val1: this.complain_detail.plot_no },
            { key: this.$t('irriPumpInformation.latitude'), val: this.complain_detail.latitude, key1: this.$t('irriPumpInformation.longitude'), val1: this.complain_detail.longitude },
            { key: this.$t('irriPumpInformation.water_group_id'), val: this.complain_detail.water_group_id },
            { key: this.$t('externalUserIrrigation.total_farmer'), val: this.$n(this.complain_detail.total_farmer) }
          ]
      }
    }
  },
  methods: {
    getMouzaName (Id) {
      const Obj = this.$store.state.IrriConfig.commonObj.mouzaList.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      }
      return ''
    },
    getPumpInformation () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
